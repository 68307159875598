import { IAddress } from '@/src/components/Checkout/Checkout.types';

const getPayPalAddress = (address: IAddress) => {
  if (
    address?.country?.length > 1 &&
    !(address?.line1 && address?.line1?.length > 1)
  ) {
    return null;
  }

  return {
    country: address?.country,
    state: address?.state,
    street_address: address?.line1,
    city: address?.city,
    zip_code: address?.postal_code,
  };
};

export default getPayPalAddress;
