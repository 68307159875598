import { usePageStore } from '@/src/common/zustand';
import * as PurchaseService from '@/src/services/PurchaseService';
import {
  ConfirmCardPaymentData,
  Stripe,
} from '@stripe/stripe-js/types/stripe-js';
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js/types/stripe-js';
import { triggerRedirect } from './redirect.helper';
import showError from './showError.helper';

const { setPaymentElementPaymentError, setIsProcessingPayment } =
  usePageStore.getState();

const handleWalletPurchase = async (
  stripe: Stripe,
  walletRequest: PaymentRequest,
  reCaptchaToken: string
) => {
  walletRequest.show();

  walletRequest.on('cancel', () => {
    setIsProcessingPayment(false);
    setPaymentElementPaymentError(null);
    walletRequest.off('paymentmethod');
  });

  walletRequest.on(
    'paymentmethod',
    async (event: PaymentRequestPaymentMethodEvent) => {
      const response = await PurchaseService.submitOrder(reCaptchaToken, {
        forceAutomaticMode: true,
      });

      const mode =
        'confirmation_type' in response
          ? response.confirmation_type
          : 'payment';

      const customer = 'customer' in response ? response.customer : null;

      if ('error' in response && 'message' in response && response.error) {
        showError(response.message);
        return;
      }

      if (!('client_secret' in response) || !response.client_secret) {
        showError('Invalid response from Payment Service');
        return;
      }

      const confirmCardPaymentOrSetup =
        mode === 'setup' ? 'confirmCardSetup' : 'confirmCardPayment';

      let paymentMethodConfig: ConfirmCardPaymentData = {
        payment_method: event.paymentMethod.id,
      };

      if (mode === 'payment') {
        paymentMethodConfig.setup_future_usage = 'off_session';
      }

      const { error } = await stripe[confirmCardPaymentOrSetup](
        response?.client_secret,
        paymentMethodConfig,
        {
          handleActions: false,
        }
      );

      if (error) {
        walletRequest.abort();
        showError(error.message);
        return;
      }

      triggerRedirect(mode, response?.client_secret, customer);
    }
  );
};

export default handleWalletPurchase;
