import { getUrlQueryString } from '@/src/services/PurchaseService/helpers';
import { getPageSlug } from '../Checkout.utils';
import { isUpsellEnabled } from '@/src/helpers';
import { useOfferStore } from '@/src/common/zustand';

const getUpsellRedirectUrl = (mode: 'payment' | 'setup', customer: string) => {
  const slug = getPageSlug();
  const lang = useOfferStore.getState().offer?.customization?.language ?? 'en';

  return `${
    window.location.origin
  }/upsell?slug=${slug}&mode=${mode}&customer=${customer}&lang=${lang}${getUrlQueryString()}`;
};

export const getThankYouRedirectUrl = () => {
  const slug = getPageSlug();

  return `${window.location.origin}/thank-you?slug=${slug}`;
};

export const getRedirectUrl = (
  mode: 'payment' | 'setup',
  customer: string | null = null
) => {
  return isUpsellEnabled() && customer
    ? getUpsellRedirectUrl(mode, customer)
    : getThankYouRedirectUrl();
};

export const triggerRedirect = (
  mode: 'payment' | 'setup',
  clientSecret: string,
  customer: string | null
) => {
  window.location.replace(
    `${getRedirectUrl(
      mode,
      customer
    )}&client_secret=${clientSecret}&redirect_status=succeeded`
  );
};
