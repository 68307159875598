import { useOfferStore } from '@/src/common/zustand';

declare global {
  interface Window {
    fpr: (action: string, data: any) => void;
  }
}

/**
 * This function attributes the customer's email address to the affiliate in FirstPromoter.
 * This is necessary because when FirstPromoter receives purchase events from Stripe (for OTP or SUB),
 * FirstPromoter will check to see if the customer email address has already been attributed to a specific
 * affiliate before generating a commission for that customer's payment.
 */
export const trackReferralInFirstPromoter = (email: string) => {
  if (!email) return;

  const offer = useOfferStore.getState().offer!;

  const firstPromoterIntegration = offer.account.integrations?.find(
    (i) => i.system_name === 'FirstPromoter'
  );

  if (firstPromoterIntegration && email && typeof window.fpr === 'function') {
    window.fpr('referral', { email: email });
  }
};
