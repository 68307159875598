import * as PurchaseService from '@/src/services/PurchaseService';
import { Stripe, StripeElements } from '@stripe/stripe-js/types/stripe-js';
import { getRedirectUrl } from './redirect.helper';

const handleCardPurchase = async (
  stripe: Stripe,
  elements: StripeElements,
  reCaptchaToken: string
) => {
  const { error: submitError } = await elements.submit();
  if (submitError) {
    throw new Error(submitError.message);
  }

  const response = await PurchaseService.submitOrder(reCaptchaToken);

  const mode =
    'confirmation_type' in response ? response.confirmation_type : 'payment';
  const customer = 'customer' in response ? response.customer : null;

  if ('error' in response && 'message' in response && response.error) {
    throw new Error(response.message);
  }

  if (!('client_secret' in response) || !response.client_secret) {
    throw new Error('Invalid response from Payment Service');
  }

  const redirectUrl = getRedirectUrl(mode, customer);

  const confirmPaymentOrSetup =
    mode === 'setup' ? 'confirmSetup' : 'confirmPayment';

  const { error } = await stripe[confirmPaymentOrSetup]({
    elements,
    clientSecret: response.client_secret,
    confirmParams: {
      return_url: redirectUrl,
    },
  });

  if (error) {
    throw new Error(error.message);
  }
};

export default handleCardPurchase;
