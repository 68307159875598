import { useOfferStore, usePageStore } from '@/src/common/zustand';

import { getMetadata, getUpsellSubInvoiceItems } from '../helpers';

import { operations as PaymentOperations } from '@/src/schemas/payments';
import { PurchaseError } from '../types';
import { getDescription } from '@/src/services/PurchaseService/helpers/getDescription';

type PostStripeSubRequestBody =
  PaymentOperations['post-stripe-subscription']['requestBody']['content']['application/json'];

export type ISubscription = Omit<
  PostStripeSubRequestBody['subscription'],
  'metadata'
> & {
  metadata: Omit<
    PostStripeSubRequestBody['subscription']['metadata'],
    'purchase_hash' | 'recaptcha_score'
  >;
};
type IPurchaseRequest = Omit<PostStripeSubRequestBody, 'subscription'> & {
  subscription: ISubscription;
  upsell: boolean;
};

type PostStripeSubResponse =
  PaymentOperations['post-stripe-subscription']['responses']['201']['content']['application/json'];

export const purchaseSubUpsell = async (
  customerId: string,
  paymentMethod: string
): Promise<PostStripeSubResponse | PurchaseError> => {
  // 1. Offer details from store
  const offer = useOfferStore.getState().offer!;
  const encryptedOffer = useOfferStore.getState().encryptedOffer!;
  const contactInformation = usePageStore.getState().contactInformationValues;

  const accountId = offer.account.id;
  const integrationId = offer.payment_integrations.find(
    (i) => i.system_name === 'Stripe'
  )!.id;
  const liveMode = offer.status === 'live';
  const currencyCode = offer?.currency?.code ?? 'USD';

  // 2. Automatic tax enabled
  const mode = liveMode ? 'livemode' : 'testmode';
  const automaticTaxEnabled =
    offer.account.integrations.find((i) => i.system_name === 'Stripe')
      ?.options?.[`stripe_tax_${mode}`] ?? false;

  // 4. Subscription items
  const subscriptionItems = [
    {
      price: offer.upsells[0].payment_option.stripe_price_id as string,
    },
  ];

  // 5. Invoice items - TODO: Add invoice items
  const invoiceItems = getUpsellSubInvoiceItems();

  // 6. Trial days
  const trialDays = offer.upsells[0].payment_option?.trial_days ?? 0;

  // 9. Metadata
  const metadata = getMetadata();

  const description = getDescription();

  // 10. Assemble request body
  const purchaseRequestBody: IPurchaseRequest = {
    account_id: accountId,
    integration_id: integrationId,
    live_mode: liveMode,
    offer: encryptedOffer,
    customer: {
      email: contactInformation?.email,
    },
    subscription: {
      automatic_tax: {
        enabled: automaticTaxEnabled,
      },
      currency: currencyCode,
      customer: customerId,
      default_payment_method: paymentMethod,
      items: subscriptionItems,
      ...(invoiceItems.length && {
        add_invoice_items: invoiceItems,
      }),
      payment_settings: {
        save_default_payment_method: 'on_subscription',
      },
      metadata: {
        ...metadata,
        type: 'upsell',
      },
      description,
      trial_period_days: trialDays,
    },
    upsell: true,
  };

  // 11. Send request to Go through Next.js psproxy API
  const query = new URLSearchParams(window.location.search);
  const offerSlug = query.get('slug')!;

  const res = await fetch(`/api/psproxy/${offerSlug}/stripe/sub/sub`, {
    method: 'POST',
    body: JSON.stringify(purchaseRequestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    return {
      error: true,
      code: res.status.toString(),
      message: 'Something went wrong. Please try again.',
    };
  }

  // 12. Return response
  return await res.json();
};
