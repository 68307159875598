import { useOfferStore } from '@/src/common/zustand';
import { ActiveElementTypes } from '../Checkout.enums';

const isBNPLMethodAvailable = (
  paymentMethods: string[] | undefined
): boolean => {
  if (useOfferStore.getState().offer?.automatic_payment_methods) {
    return false;
  }

  return [
    ActiveElementTypes.Affirm,
    ActiveElementTypes.Klarna,
    ActiveElementTypes.AfterPayClearPay,
  ].some((type) => {
    return paymentMethods?.includes(type);
  });
};

export default isBNPLMethodAvailable;
