import { usePageStore } from '@/src/common/zustand';
import * as PurchaseService from '@/src/services/PurchaseService';
import {
  ConfirmAffirmPaymentData,
  ConfirmAfterpayClearpayPaymentData,
  ConfirmKlarnaPaymentData,
  Stripe,
  StripeElements,
} from '@stripe/stripe-js/types/stripe-js';
import { getAddress } from '@/src/components/Checkout/Checkout.utils';
import { ActiveElementTypes } from '../Checkout.enums';
import { getThankYouRedirectUrl } from './redirect.helper';

const getConfirmMethod = (activeElement: ActiveElementTypes) => {
  switch (activeElement) {
    case ActiveElementTypes.Affirm:
      return 'confirmAffirmPayment';
    case ActiveElementTypes.Klarna:
      return 'confirmKlarnaPayment';
    case ActiveElementTypes.AfterPayClearPay:
      return 'confirmAfterpayClearpayPayment';
    default:
      null;
  }
};

const handleBNPLPurchase = async (
  stripe: Stripe,
  elements: StripeElements,
  activeElement: ActiveElementTypes,
  reCaptchaToken: string
) => {
  const contactInformation = usePageStore.getState().contactInformationValues;
  const address = getAddress();

  if (!contactInformation || !address) {
    return;
  }

  const { error: submitError } = await elements.submit();
  if (submitError) {
    throw new Error(submitError.message);
  }

  const response = await PurchaseService.submitOrder(reCaptchaToken);

  if ('error' in response && 'message' in response) {
    throw new Error(response.message);
  }

  const confirmBNPLPayment = getConfirmMethod(activeElement);

  if (!confirmBNPLPayment) {
    return;
  }

  let confirmPayload:
    | (ConfirmAffirmPaymentData &
        ConfirmKlarnaPaymentData &
        ConfirmAfterpayClearpayPaymentData)
    | undefined = {
    payment_method: {
      billing_details: {
        email: contactInformation.email,
        name: `${contactInformation.first_name} ${contactInformation.last_name}`,
        address: {
          line1: address.line1,
          city: address.city,
          state: address.state,
          postal_code: address.postal_code,
          country: address.country,
        },
      },
    },
    return_url: getThankYouRedirectUrl(),
  };

  if (activeElement !== ActiveElementTypes.Klarna) {
    confirmPayload = {
      ...confirmPayload,
      shipping: {
        name: `${contactInformation.first_name} ${contactInformation.last_name}`,
        address: {
          line1: address.line1 as string,
          city: address.city,
          state: address.state,
          postal_code: address.postal_code,
          country: address.country,
        },
      },
    };
  }

  const { error } = await stripe[confirmBNPLPayment](
    response.client_secret,
    confirmPayload
  );

  if (error) {
    throw new Error(error.message);
  }
};

export default handleBNPLPurchase;
