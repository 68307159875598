import { purchaseOtpMain } from './core/purchaseOtpMain';
import { purchaseSubMain } from './core/purchaseSubMain';

import { operations as PaymentOperations } from '@/src/schemas/payments';

import { PurchaseError } from './types';
import * as RecaptchaService from '@/src/services/RecaptchaService';
import { PaymentType } from '@/src/components/Checkout/Checkout.enums';
import { getSelectedPaymentOption } from './helpers';

type PostStripeOtpResponse =
  PaymentOperations['post-stripe-otp']['responses']['201']['content']['application/json'];
export type PostStripeSubResponse =
  PaymentOperations['post-stripe-subscription']['responses']['201']['content']['application/json'];

export type SubmitOrderResponse =
  | PostStripeSubResponse
  | PostStripeOtpResponse
  | PurchaseError;

/**
 * Options for the submitOrder function.
 */
export type SubmitOrderOptions = {
  /**
   * If true, the order submission will be forced to use automatic mode (automatic payment methods).
   * This can be useful in scenarios where custom payment methods are available and they can be used for submission,
   * but we need to override that for a specific order submission case (example: Google Pay, Apple Pay).
   */
  forceAutomaticMode?: boolean;
};

const submitOrder = async (
  reCaptchaToken: string,
  options: SubmitOrderOptions = {}
): Promise<SubmitOrderResponse> => {
  // Get currently selected payment option
  const selectedPaymentOption = getSelectedPaymentOption();

  // Get ReCaptcha score (if token not provided, default to 1)
  const reCaptchaScore = !!reCaptchaToken
    ? await RecaptchaService.getScore({
        reCaptchaToken,
      })
    : 1;

  // Route to correct purchase function based on payment type
  if (selectedPaymentOption?.payment_type === PaymentType.one_time_fee) {
    return await purchaseOtpMain(reCaptchaScore, options);
  } else {
    return await purchaseSubMain(reCaptchaScore, options);
  }
};

export default submitOrder;
