import { handleRequest } from '@/src/services/helpers';

const getScore = async (payload: object): Promise<number> =>
  await handleRequest('/api/recaptcha', 'POST', payload)
    .then(handleResponse(true))
    .then((data) => {
      return data.reCaptchaScore;
    })
    .catch(logError);

const handleResponse =
  (json: boolean = true) =>
  (response) => {
    if (!response.ok) {
      throw new Error(`Network response was not OK.`);
    }
    return json ? response.json() : response;
  };

const logError = (error) => {
  console.error('There has been a problem with your fetch operation:', error);
};

export default getScore;
