import { useOfferStore } from '@/src/common/zustand';
import { purchaseOtpUpsell } from './core/purchaseOtpUpsell';
import { purchaseSubUpsell } from './core/purchaseSubUpsell';

import { operations as PaymentOperations } from '@/src/schemas/payments';

import { PurchaseError } from './types';

type PostStripeOtpResponse =
  PaymentOperations['post-stripe-otp']['responses']['201']['content']['application/json'];
export type PostStripeSubResponse =
  PaymentOperations['post-stripe-subscription']['responses']['201']['content']['application/json'];

export type SubmitOrderResponse =
  | PostStripeSubResponse
  | PostStripeOtpResponse
  | PurchaseError;

const submitUpsell = async (
  customerId: string,
  paymentMethod: string
): Promise<SubmitOrderResponse> => {
  const selectedUpsell = (useOfferStore.getState().offer?.upsells)![0];

  const upsellType = selectedUpsell.payment_option.payment_type;

  if (upsellType === 'one_time_fee') {
    return await purchaseOtpUpsell(customerId, paymentMethod);
  } else {
    return await purchaseSubUpsell(customerId, paymentMethod);
  }
};

export default submitUpsell;
