import { PurchaseError } from '../types';

const unknownError = 'There was an error processing your order.';

const getMessage = (stripeErrorCode: string): string => {
    switch (stripeErrorCode) {
        case 'tax_id_invalid':
            return 'Provided Tax ID / VAT format is invalid.';
        default: return unknownError
    }
}

export const getStripeError = async (response: Response): Promise<PurchaseError> => {
    try {
        const errorResponse = await response.json();
        const errorMessage = JSON.parse(errorResponse.message);
        return {
            error: true,
            code: response.status.toString(),
            message: getMessage(errorMessage.code)
        };
    } catch (error) {
        return {
            error: true,
            code: response.status.toString(),
            message: unknownError
        };
    }
}