import { usePageStore } from '@/src/common/zustand';
import { makePurchase } from '@/src/common/api';
import { GDPR } from '@/src/components/Checkout/Checkout.constants';
import getPayPalAddress from '@/src/components/PayPalForm/helpers/getPayPalAddress.helper';

const createPayPalOrder = async (
  basePayload,
  reCaptchaToken,
  gdprEnabled
): Promise<string | undefined> => {
  const contactInformationFromState =
    usePageStore.getState().contactInformationValues;

  // Remove empty keys from contactInformation object
  // to prevent empty phone field from being included in payload
  // when phone field is set to optional in admin
  const contactInformation = Object.fromEntries(
    Object.entries(contactInformationFromState!).filter(
      ([_, v]) => v != null && v != ''
    )
  );

  // Clean first name and last name fields to only send single word for each
  if (contactInformation?.first_name) {
    contactInformation.first_name = contactInformation?.first_name
      ? (contactInformation?.first_name as string).replace(/ .*/, '').trim()
      : '';
  }

  if (contactInformation?.last_name) {
    contactInformation.last_name = contactInformation?.last_name
      ? (contactInformation?.last_name as string).replace(/ .*/, '').trim()
      : '';
  }

  let address = getPayPalAddress(usePageStore.getState().addressValues!);

  const ipAddress = usePageStore.getState().ipAddress;
  const inEU = usePageStore.getState().inEU;
  const termsAndConditionsValues =
    usePageStore.getState().termsAndConditionsValues;
  const discountCode = usePageStore.getState().discountCode;

  if (discountCode) {
    basePayload['coupon_code'] = discountCode;
  }

  const payload = {
    ...basePayload,
    active_bump: usePageStore.getState().bumpSelected,
    payment_option_id: usePageStore.getState().selectedPaymentOptionId,
    customer: { ...contactInformation, ...address },
    ip_address: ipAddress,
    gdpr: {
      in_eu: inEU,
      comply: Boolean(termsAndConditionsValues?.gdpr) ?? false,
      gdpr_visibility:
        gdprEnabled === GDPR.ALL || (gdprEnabled === GDPR.EU && inEU),
    },
    recaptcha_token: reCaptchaToken,
  };

  return makePurchase(payload).then((res) => {
    // TODO - error?
    return res?.data?.paypal?.order_id;
  });
};

export default createPayPalOrder;
