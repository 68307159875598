import { usePageStore } from '@/src/common/zustand';

const { setIsProcessingPayment, setPaymentElementPaymentError } =
  usePageStore.getState();

const showError = (error: string = 'Something went wrong.') => {
  setPaymentElementPaymentError({
    error,
  });
  setIsProcessingPayment(false);
};

export default showError;
