import { useOfferStore } from '@/src/common/zustand';
import { IPaymentIntent } from '../core/purchaseOtpMain';

type Description = IPaymentIntent['description'];

export const getUpsellDescription = (): Description => {
  const {
    upsells: [upsell],
  } = useOfferStore.getState().offer!;

  if (!upsell) {
    return '';
  }

  if (!upsell.product?.name) {
    return `${upsell.label}`;
  }

  return `${upsell.label} / ${upsell.product.name}`;
};
