import { getMinUnitAmount } from '@/src/common/components/OrderSummary/utils';
import { CURRENCY_CODE } from '../Checkout.constants';
import { ActiveElementTypes } from '../Checkout.enums';
import isBNPLMethodAvailable from './isBNPLMethodAvailable.helper';

// TODO: This method is very strict, it ignores all custom payment methods if just one of them is not valid.
// It should be refactored to be used as a filter that keeps the payment methods that are valid.
// That requires an additional change in the Checkout component.
// Also, we should consider renaming it to something like "filterValidPaymentMethods".
const canUseCustomPaymentMethods = (
  customPaymentMethods: string[],
  mode: 'payment' | 'subscription',
  amount: number,
  currencyCode: string
): boolean => {
  if (!customPaymentMethods?.length) {
    return false;
  }

  if (mode === 'subscription') {
    return (
      !isBNPLMethodAvailable(customPaymentMethods) &&
      !customPaymentMethods.includes(ActiveElementTypes.Ideal)
    );
  }

  // More info about the rules for the minimum amount for each BNPL provider here:
  // https://docs.stripe.com/payments/buy-now-pay-later#product-support
  if (customPaymentMethods.includes(ActiveElementTypes.Affirm)) {
    return amount >= getMinUnitAmount(currencyCode) * 50;
  }

  if (customPaymentMethods.includes(ActiveElementTypes.Klarna)) {
    return amount >= getMinUnitAmount(currencyCode) * 10;
  }

  if (customPaymentMethods.includes(ActiveElementTypes.AfterPayClearPay)) {
    return amount >= getMinUnitAmount(currencyCode);
  }

  if (
    customPaymentMethods.includes(ActiveElementTypes.SepaDebit) ||
    customPaymentMethods.includes(ActiveElementTypes.Ideal)
  ) {
    return currencyCode === CURRENCY_CODE.EUR;
  }

  return true;
};

export default canUseCustomPaymentMethods;
