import { useOfferStore, usePageStore } from '@/src/common/zustand';

import * as TaxService from '@/src/services/TaxService';
import { getMetadata } from '../helpers';

import { operations as PaymentOperations } from '@/src/schemas/payments';
import { PurchaseError } from '../types';
import { isStripeTaxEnabled } from '@/src/helpers';
import { getUpsellDescription } from '../helpers/getUpsellDescription';

type PostStripeOtpRequestBody =
  PaymentOperations['post-stripe-otp']['requestBody']['content']['application/json'];

type PostStripeOtpResponse =
  PaymentOperations['post-stripe-otp']['responses']['201']['content']['application/json'];

export type IPaymentIntent = Omit<
  PostStripeOtpRequestBody['payment_intent'],
  'metadata'
> & {
  metadata: Omit<
    PostStripeOtpRequestBody['payment_intent']['metadata'],
    'purchase_hash' | 'recaptcha_score'
  >;
};
type IPurchaseRequest = Omit<PostStripeOtpRequestBody, 'payment_intent'> & {
  payment_intent: IPaymentIntent;
  upsell: boolean;
};

export const purchaseOtpUpsell = async (
  customerId: string,
  paymentMethod: string
): Promise<PostStripeOtpResponse | PurchaseError> => {
  // Offer details from store
  const offer = useOfferStore.getState().offer!;
  const encryptedOffer = useOfferStore.getState().encryptedOffer!;
  const contactInformation = usePageStore.getState().contactInformationValues;

  const accountId = offer.account.id;
  const integrationId = offer.payment_integrations.find(
    (i) => i.system_name === 'Stripe'
  )!.id;
  const liveMode = offer.status === 'live';
  const currencyCode = offer?.currency?.code ?? 'USD';
  const isTaxAvailable = isStripeTaxEnabled();

  // Calculate taxes
  const addressValues = usePageStore.getState().addressValues;

  let taxAmount = 0,
    taxCalculationId: string | null = null;

  if (addressValues?.line1 && isTaxAvailable) {
    const taxResponse =
      (await TaxService.calculateOtpUpsellTax(addressValues)) ?? null;

    if (taxResponse?.id && taxResponse?.tax_amount_exclusive) {
      taxCalculationId = taxResponse.id;
      taxAmount = taxResponse.tax_amount_exclusive;
    }
  }

  // Calculate total price
  const total = offer.upsells[0].payment_option.unit_amount! + taxAmount;

  // Metadata
  const metadata = getMetadata();

  // Description
  const description = getUpsellDescription();

  // Custom payment methods
  const customPaymentMethods = useOfferStore
    .getState()
    .offer?.payment_methods?.map((method) => method.type as string);

  // Assemble request body
  const purchaseRequestBody: IPurchaseRequest = {
    account_id: accountId,
    integration_id: integrationId,
    customer: {
      email: contactInformation?.email,
    },
    live_mode: liveMode,
    offer: encryptedOffer,
    payment_intent: {
      amount: total,
      currency: currencyCode,
      customer: customerId,
      metadata: {
        ...metadata,
        type: 'upsell',
        tax_calculation: taxCalculationId,
      },
      description,
      ...(customPaymentMethods?.length && {
        payment_method_types: customPaymentMethods,
      }),
      payment_method: paymentMethod,
      confirm: true,
    },
    upsell: true,
  };

  // Send request to Go through Next.js psproxy API
  const query = new URLSearchParams(window.location.search);
  const offerSlug = query.get('slug')!;

  const res: Response = await fetch(
    `/api/psproxy/${offerSlug}/stripe/otp/otp`,
    {
      method: 'POST',
      body: JSON.stringify(purchaseRequestBody),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  if (!res.ok) {
    return {
      error: true,
      code: res.status.toString(),
      message: 'Something went wrong. Please try again.',
    };
  }

  // 7. Return response
  return await res.json();
};
