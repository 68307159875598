import { useOfferStore, usePageStore } from '@/src/common/zustand';

import { getCustomer, getMainOtpTotal, getMetadata } from '../helpers';

import { operations as PaymentOperations } from '@/src/schemas/payments';
import { PurchaseError } from '../types';
import { isBNPLMethodAvailable } from '@/src/components/Checkout/helpers';
import { SubmitOrderOptions } from '../submitOrder';
import { getDescription } from '../helpers/getDescription';
import { trackReferralInFirstPromoter } from '../helpers/trackReferralInFirstPromoter';
import { getStripeError } from '../helpers/getStripeError';

type PostStripeOtpRequestBody =
  PaymentOperations['post-stripe-otp']['requestBody']['content']['application/json'];

type PostStripeOtpResponse =
  PaymentOperations['post-stripe-otp']['responses']['201']['content']['application/json'];

export type IPaymentIntent = Omit<
  PostStripeOtpRequestBody['payment_intent'],
  'metadata'
> & {
  metadata: Omit<
    PostStripeOtpRequestBody['payment_intent']['metadata'],
    'purchase_hash'
  >;
};
type IPurchaseRequest = Omit<PostStripeOtpRequestBody, 'payment_intent'> & {
  payment_intent: IPaymentIntent;
};

export const purchaseOtpMain = async (
  reCaptchaScore: number,
  { forceAutomaticMode }: SubmitOrderOptions
): Promise<PostStripeOtpResponse | PurchaseError> => {
  // Offer details from store
  const offer = useOfferStore.getState().offer!;
  const encryptedOffer = useOfferStore.getState().encryptedOffer!;

  const accountId = offer.account.id;
  const integrationId = offer.payment_integrations.find(
    (i) => i.system_name === 'Stripe'
  )!.id;
  const liveMode = offer.status === 'live';
  const currencyCode = offer?.currency?.code ?? 'USD';

  // Payment element loading mode (check if custom payment methods can be used)
  const paymentElementLoadingMode =
    usePageStore.getState().paymentElementLoadingMode;

  // Order bump
  const bumpSelected = usePageStore.getState().bumpSelected;

  // Discount
  const discountEncrypted = usePageStore.getState().discount?.encrypted;

  // Customer
  const customer = getCustomer();

  // Calculate total price
  const total = getMainOtpTotal();

  // Metadata
  const metadata = getMetadata();

  // Description
  const description = getDescription();

  // Tax calculation ID
  const { tax_calculation_id } = usePageStore.getState().taxCalcOTP;

  // Custom payment methods
  const customPaymentMethods = useOfferStore
    .getState()
    .offer?.payment_methods?.map((method) => method.type as string);

  const loadingMode = !!forceAutomaticMode
    ? 'automatic'
    : paymentElementLoadingMode;

  // Assemble request body
  const purchaseRequestBody: IPurchaseRequest = {
    account_id: accountId,
    customer,
    integration_id: integrationId,
    ...(discountEncrypted && { discount: discountEncrypted }),
    live_mode: liveMode,
    offer: encryptedOffer,
    payment_intent: {
      amount: total,
      currency: currencyCode,
      metadata: {
        ...metadata,
        type: bumpSelected ? 'main+bump' : 'main',
        recaptcha_score: reCaptchaScore,
        tax_calculation: tax_calculation_id,
      },
      description,
      ...((loadingMode === 'automatic' ||
        !isBNPLMethodAvailable(customPaymentMethods)) && {
        setup_future_usage: 'off_session',
      }),
      ...(loadingMode === 'custom' && {
        payment_method_types: customPaymentMethods,
      }),
      automatic_payment_methods: {
        enabled: loadingMode === 'automatic',
      },
    },
    payment_option_id: usePageStore.getState().selectedPaymentOptionId,
  };

  // 10. Send request to Go through Next.js psproxy API
  const pathName = window.location.pathname.replace(new RegExp('/', 'g'), '-');
  const pageSlug = pathName.charAt(0) === '-' ? pathName.slice(1) : pathName;

  const res: Response = await fetch(`/api/psproxy/${pageSlug}/stripe/otp/otp`, {
    method: 'POST',
    body: JSON.stringify(purchaseRequestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    return await getStripeError(res);
  }

  // 11. Check if there's a FirstPromoter integration
  if (customer.email) {
    trackReferralInFirstPromoter(customer.email);
  }

  // 12. Return response
  return await res.json();
};
